/* eslint-disable react/jsx-no-bind */
import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import { VAS_HASH } from 'Constants/items';
import PropTypes from 'prop-types';
import NoAds from 'Components/Listing/components/NoAds';
import LabelText from '../LabelText/LabelText.APP_TARGET';
import { ORIGINAL_ITEMS, SUGGESTED_ITEMS } from 'Constants/items';
import AsyncSliderListing from 'Components/ItemSlider/async/AsyncSliderListing.APP_TARGET';
import loadable from '@loadable/component';
import { withConfig } from 'HOCs/withConfig/withConfig';
import VasSheetAsync from 'Components/VasSheet/VasSheetAsync';
import { SLIDER_TYPE } from 'Constants/listingSlider';
import { getCityLocationId } from 'Helpers/locations';
import { noop } from 'Helpers/function';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
import { isFranchiseView } from 'Helpers/item';

const ItemViewListingMX = loadable(() => import(/* webpackChunkName: "itemViewListingMX" */'Components/Listing/listingMXComponents/ItemViewListing/ItemViewListing'));
const ItemViewListingOld = loadable(() => import(/* webpackChunkName: "itemViewListingOld" */'Components/Listing/components/ItemViewListing'));

export const ListingContent = ({
    isFetching,
    showStatistics,
    type,
    visualizationType,
    onViewItem,
    fourColumns,
    categoryTree,
    itemPostPlaceholderOrigin,
    enabledRealImpressions,
    listingType,
    getListingDescription,
    filters,
    placeholderImagesStyle,
    onItemClick,
    source,
    loadMoreBtn,
    showInspectionTag,
    showMXDesign,
    isListingPage,
    openModal,
    items,
    similarAdsData,
    similarAdsCount,
    sectionsItems,
    suggestedItemsSections,
    parentItems,
    originalTotal,
    suggestedItemsTotal,
    listingSliderType,
    isOpened,
    closeModal,
    marketConfig,
    extraTrackAttr,
    itemsMetadata,
    categoryID,
    listingBodyType,
    isShowVideoTag,
    isNewUspVasDesign,
    shouldRenderZRPWidgets,
    locationId,
    showSponseredTag,
    showDealerTag,
    onLoadNextPage,
    location,
    config,
    adIndexArr
}) => {
    useEffect(() => {
        closeModal();
    }, [closeModal]);

    const [loadVasSheet, setLoadVasSheet] = useState(false);
    const {
        original_label,
        original_term,
        show_hint,
        show_original_items,
        show_suggested_items,
        suggested_label,
        suggested_term,
        suggestions_offset
    } = itemsMetadata;

    const showNewDesign = marketConfig.get('adpvAuto', 'showNewDesign');
    const initalLoad = 300;

    const handleVasTagTap = () => {
        if (loadVasSheet) {
            openModal();
        }
        else {
            setLoadVasSheet(true);
            setTimeout(() => openModal(), initalLoad);
        }
    };

    const getItemsListing = ({ items = [], term, sectionsItems, label, total, labelType, renderLoadMore = false, showPlaceHolder, showBP = true }) => {
        const isAdSuggested = labelType === SUGGESTED_ITEMS;
        const showNewCardDesign = isFranchiseView(config, categoryID);

        const ItemViewListing = showNewCardDesign ? ItemViewListingMX : ItemViewListingOld;

        if (!items.length && !similarAdsCount && !isFetching) {
            return null;
        }

        return (
            <Fragment>
                {
                    label
                    && <LabelText
                        labelType={ labelType }
                        label={ label }
                        term={ term }
                        total={ total }
                        show_hint={ show_hint }
                        show_original_items={ show_original_items }
                        itemsMetadata={ itemsMetadata }
                        marketConfig={ marketConfig }
                    />
                }
                <ItemViewListing
                    items={ items }
                    similarAdsData={ similarAdsData }
                    similarAdsCount={ similarAdsCount }
                    isFetching={ isFetching }
                    showStatistics={ showStatistics }
                    type={ type }
                    categoryTree={ categoryTree }
                    visualizationType={ visualizationType }
                    onViewItem={ onViewItem }
                    fourColumns={ fourColumns }
                    itemPostPlaceholderOrigin={ itemPostPlaceholderOrigin }
                    enabledRealImpressions={ enabledRealImpressions }
                    listingType={ listingType }
                    getListingDescription={ getListingDescription }
                    sectionsItems={ sectionsItems }
                    filters={ filters }
                    placeholderImagesStyle={ placeholderImagesStyle }
                    onItemClick={ onItemClick }
                    isAdSuggested={ isAdSuggested }
                    source={ source }
                    loadMoreBtn={ renderLoadMore ? loadMoreBtn : null }
                    showInspectionTag={ showInspectionTag }
                    isListingPage={ isListingPage }
                    onVasTagTap={ handleVasTagTap }
                    extraTrackAttr={ extraTrackAttr }
                    showPlaceHolder={ showPlaceHolder }
                    showBP={ showBP }
                    categoryID={ categoryID }
                    locationId={ locationId }
                    listingBodyType={ listingBodyType }
                    isNewUspVasDesign={ isNewUspVasDesign }
                    originalTotal={ originalTotal }
                    shouldRenderZRPWidgets={ shouldRenderZRPWidgets }
                    isShowVideoTag={ isShowVideoTag }
                    showSponseredTag={ showSponseredTag }
                    showDealerTag={ showDealerTag }
                    onLoadNextPage={ onLoadNextPage }
                    location={ location }
                    adIndexArr={ adIndexArr }
                />
            </Fragment>
        );
    };

    let originalItems;
    let suggestedItems;

    if (show_suggested_items) {
        originalItems = items.slice(0, suggestions_offset);
        suggestedItems = items.slice(suggestions_offset);
    }
    else {
        originalItems = items;
    }

    const isSuggestedItemsPresent = typeof suggestedItems !== 'undefined';

    return (
        <>
            {listingSliderType && <AsyncSliderListing sliderType={ listingSliderType } />}
            {parentItems && !sectionsItems
                && <NoAds
                    selectedLocation={ parentItems.locationFrom }
                    parentLocation={ parentItems.locationTo }
                />
            }
            {getItemsListing({
                items: originalItems,
                term: original_term,
                sectionsItems,
                label: original_label,
                total: originalTotal,
                labelType: ORIGINAL_ITEMS,
                renderLoadMore: !isSuggestedItemsPresent,
                showPlaceHolder: true
            })}
            {suggestedItems && getItemsListing({
                items: suggestedItems,
                term: suggested_term,
                sectionsItems: suggestedItemsSections,
                label: suggested_label,
                total: suggestedItemsTotal,
                labelType: SUGGESTED_ITEMS,
                renderLoadMore: !isSuggestedItemsPresent,
                showPlaceHolder: false
            })}
            {(showMXDesign || showNewDesign) && loadVasSheet
                && <VasSheetAsync
                    isOpened={ isOpened }
                    onVasBottomSheetClose={ closeModal }
                    isNewUspVasDesign={ isNewUspVasDesign }
                />}
        </>
    );
};

ListingContent.propTypes = {
    isFetching: PropTypes.bool,
    items: PropTypes.array.isRequired,
    similarAdsData: PropTypes.array,
    similarAdsCount: PropTypes.number,
    isShowVideoTag: PropTypes.bool,
    type: PropTypes.string,
    onViewItem: PropTypes.func,
    showStatistics: PropTypes.bool,
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list']),
    fourColumns: PropTypes.bool,
    itemPostPlaceholderOrigin: PropTypes.string,
    enabledRealImpressions: PropTypes.bool,
    parentItems: PropTypes.shape({
        locationFrom: PropTypes.string,
        locationTo: PropTypes.string
    }),
    listingType: PropTypes.string,
    getListingDescription: PropTypes.func,
    sectionsItems: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string
            })
        })
    ),
    suggestedItemsSections: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string
            })
        })
    ),
    originalTotal: PropTypes.number,
    suggestedItemsTotal: PropTypes.number,
    filters: PropTypes.object,
    placeholderImagesStyle: PropTypes.string,
    onItemClick: PropTypes.func,
    source: PropTypes.string.isRequired,
    loadMoreBtn: PropTypes.node,
    listingSliderType: PropTypes.oneOf([SLIDER_TYPE.INSPECTION, SLIDER_TYPE.OLX_AUTOS, null]),
    showInspectionTag: PropTypes.bool,
    showMXDesign: PropTypes.bool,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    isListingPage: PropTypes.bool,
    isOpened: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    extraTrackAttr: PropTypes.object,
    itemsMetadata: PropTypes.object,
    categoryID: PropTypes.string,
    locationId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    categoryTree: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    listingBodyType: PropTypes.string,
    isNewUspVasDesign: PropTypes.bool,
    shouldRenderZRPWidgets: PropTypes.bool,
    showSponseredTag: PropTypes.bool,
    showDealerTag: PropTypes.bool,
    onLoadNextPage: PropTypes.func,
    location: PropTypes.shape({
        query: PropTypes.shape({})
    }),
    config: PropTypes.object,
    adIndexArr: PropTypes.array
};

ListingContent.defaultProps = {
    isFetching: false,
    items: [],
    type: 'public',
    onViewItem: () => { },
    showStatistics: false,
    visualizationType: 'grid',
    fourColumns: false,
    itemPostPlaceholderOrigin: '',
    enabledRealImpressions: false,
    similarAdsData: [],
    listingType: 'items',
    onItemClick: () => { },
    getListingDescription: () => { },
    filters: {},
    placeholderImagesStyle: '',
    source: '',
    loadMoreBtn: null,
    listingSliderType: null,
    showInspectionTag: false,
    showMXDesign: false,
    isListingPage: false,
    extraTrackAttr: {},
    itemsMetadata: {},
    locationId: '',
    listingBodyType: '',
    showSponseredTag: false,
    onLoadNextPage: noop
};

export const mapStateToProps = state => {
    const locationId = getCityLocationId(state.locations?.selectedLocation)?.toString();

    return {
        locationId
    };
};

export default compose(
    withBackButtonHandling(VAS_HASH),
    withSessionFeature,
    withConfig('marketConfig'),
    connect(mapStateToProps),
    injectIntl
)(ListingContent);
