import { getItemUrl } from 'Helpers/url';
import { getItemParameters } from 'Helpers/item';
const DEFAULT_PAGE_SIZE = 40;

/**
 * It is called dynamically from buildSchema()
 * @param props
 * @returns {{contactPoint: [{'@type': string, telephone: (string|*), contactType: (string|*), email}], '@type': string, potentialAction: {'query-input': string, '@type': string, target: (string|*)}, name, logo, description, inLanguage: (string|*), '@context': string, url: *, sameAs: (string[]|string[]|*)}}
 */
const buildListing = props => {
    const itemListElement = props.currentPageItems?.slice(0, DEFAULT_PAGE_SIZE)?.map((item, idx) => {
        const { make, model } = getItemParameters(item);

        return {
            '@type': 'ListItem',
            name: make && model ? `${make} ${model}` : item?.title,
            position: idx + 1,
            url: getItemUrl(item, null, props.categories),
            description: item?.description,
            image: item?.images?.[0]?.url
        };
    });

    return {
        '@context': 'https://schema.org',
        '@graph': {
            '@type': 'ItemList',
            itemListElement
        }
    };
};

export default buildListing;
