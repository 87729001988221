/* global window */
import React, { useEffect } from 'react';

function CleverComponentAdvertisement() {
    useEffect(() => {
        const loadScript = () => {
            if (window.__cleverScriptLoaded) {
                return;
            }

            window.__cleverScriptLoaded = true;

            const script = document.createElement('script');

            script.id = 'CleverCoreLoader89054';
            script.src = 'https://scripts.cleverwebserver.com/08492e6975b03597bd4f8659b650362b.js';
            script.async = true;
            script.type = 'text/javascript';
            script.setAttribute('data-target', window.name || (window.frameElement && window.frameElement.getAttribute('id')));

            document.body.appendChild(script);
        };

        const handleScroll = () => {
            loadScript(); // Load script after first scroll
            window.removeEventListener('scroll', handleScroll); // Remove listener after first trigger
        };

        if (document.readyState === 'complete') {
            window.addEventListener('scroll', handleScroll, { once: true }); // Ensures it runs only once
        }
        else {
            window.addEventListener('load', () => {
                window.addEventListener('scroll', handleScroll, { once: true });
            }, { once: true });
        }

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return <></>;
}

export default CleverComponentAdvertisement;
