import { urls } from 'Reducers/monetizers';
import { urls as locationsUrls } from 'Reducers/locations';
import { createSelector } from 'reselect';
import { paramsToQueryString } from 'Helpers/url';
import { getLoggedUser } from 'Selectors/user';
import { getSelectedLangCode } from 'Selectors/config';
import { INVOICES_LIMIT } from 'Constants/monetizers';
import memoize from 'lodash/memoize';
import { getType } from '../reducers/helpers/typeModifier.js';

export const getLangParams = state => {
    const langParams = {
        lang: getSelectedLangCode(state)
    };

    return langParams.lang ? langParams : null;
};

export const isFetching = (state, url) => {
    return Boolean(state.monetizer.isFetching[url]);
};

export const isError = (state, url) => {
    return Boolean(state.monetizer.isError[url]);
};

export const isFetchingUserOrders = createSelector(
    state => state,
    getLoggedUser,
    getLangParams,
    (state, user, langParams) => {
        const url = langParams ? `${urls.userPackages(user.id)}?${paramsToQueryString(langParams)}` : `${urls.userPackages(user.id)}`;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);

export const userOrdersSelector = createSelector(
    state => state.monetizer,
    getLoggedUser,
    getLangParams,
    (monetizer, user, langParams) => {
        const url = langParams ? `${urls.userPackages(user.id)}?${paramsToQueryString(langParams)}` : `${urls.userPackages(user.id)}`;
        const type = getType('MONETIZERS', url);

        return user.id ? monetizer.collections[type] : {};
    }
);

export const userInvoicesSelector = createSelector(
    state => state.monetizer,
    getLangParams,
    (monetizer, langParams) => memoize(
        offset => {
            const queryParams = {
                offset,
                limit: INVOICES_LIMIT,
                ...langParams
            };
            const url = `${urls.orders}?${paramsToQueryString(queryParams)}`;
            const type = getType('MONETIZERS', url);

            return monetizer.collections[type];
        }
    )
);

export const isFetchingUserInvoices = createSelector(
    state => state,
    getLangParams,
    (state, langParams) => {
        const queryParams = {
            offset: 0,
            limit: INVOICES_LIMIT,
            ...langParams
        };
        const url = `${urls.orders}?${paramsToQueryString(queryParams)}`;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);

export const billingInfoSelector = createSelector(
    state => state.monetizer,
    (state, customParams) => customParams,
    getLangParams,
    (monetizer, customParams, langParams) => {
        const queryParams = { ...customParams, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.billing}?${paramsToQueryString(queryParams)}` : urls.billing;
        const type = getType('MONETIZERS', url);

        return monetizer.collections[type];
    }
);

export const gstDetailsSelector = createSelector(
    state => state.monetizer,
    getLangParams,
    (monetizer, langParams) => {
        const { gstin } = monetizer;
        const queryParams = { gstin, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.getGSTDetails}?${paramsToQueryString(queryParams)}` : urls.getGSTDetails;
        const type = getType('MONETIZERS', url);

        return monetizer.collections[type];
    }
);

export const validationBillingSelector = createSelector(
    state => state.monetizer,
    (state, customParams) => customParams,
    getLangParams,
    (monetizer, customParams, langParams) => {
        const queryParams = { ...customParams, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.saveBilling}?${paramsToQueryString(queryParams)}` : urls.saveBilling;
        const type = getType('MONETIZERS', url);

        return monetizer.validation[type];
    }
);

export const errorMessageBillingSelector = createSelector(
    state => state.monetizer,
    (state, customParams) => customParams,
    getLangParams,
    (monetizer, customParams, langParams) => {
        const queryParams = { ...customParams, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.billing}?${paramsToQueryString(queryParams)}` : urls.billing;
        const type = getType('MONETIZERS', url);

        // set errorMessage only if field errors are not present
        if (typeof monetizer.validation[type] === 'boolean') {
            return monetizer.errorMessage[type];
        }
        return undefined;
    }
);

export const validationVerifyGstSelector = createSelector(
    state => state.monetizer,
    getLangParams,
    (monetizer, langParams) => {
        const { gstin } = monetizer;
        const queryParams = { gstin, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.getGSTDetails}?${paramsToQueryString(queryParams)}` : urls.getGSTDetails;
        const type = getType('MONETIZERS', url);

        return monetizer.validation[type];
    }
);

export const errorMessageVerifyGstSelector = createSelector(
    state => state.monetizer,
    getLangParams,
    (monetizer, langParams) => {
        const { gstin } = monetizer;
        const queryParams = { gstin, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.getGSTDetails}?${paramsToQueryString(queryParams)}` : urls.getGSTDetails;
        const type = getType('MONETIZERS', url);

        // set errorMessage only if field errors are not present
        if (typeof monetizer.validation[type] === 'boolean') {
            return monetizer.errorMessage[type];
        }
        return undefined;
    }
);

export const isFetchingBillingInfo = createSelector(
    state => state,
    (state, customParams) => customParams,
    getLangParams,
    (state, customParams, langParams) => {
        const queryParams = { ...customParams, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.billing}?${paramsToQueryString(queryParams)}` : urls.billing;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);

export const isSavingBillingInfo = createSelector(
    state => state,
    (state, customParams) => customParams,
    getLangParams,
    (state, customParams, langParams) => {
        const queryParams = { ...customParams, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.saveBilling}?${paramsToQueryString(queryParams)}` : urls.saveBilling;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);

export const isFetchingGSTDetails = createSelector(
    state => state,
    getLangParams,
    (state, langParams) => {
        const { monetizer: { gstin } = {}} = state;
        const queryParams = { gstin, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.getGSTDetails}?${paramsToQueryString(queryParams)}` : urls.getGSTDetails;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);

export const isFetchingMultiPayment = createSelector(
    state => state,
    getLangParams,
    (state, langParams) => {
        const url = langParams ? `${urls.purchaseMulti}?${paramsToQueryString(langParams)}` : urls.purchaseMulti;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);

export const isFetchingMultiPaymentError = createSelector(
    state => state,
    getLangParams,
    (state, langParams) => {
        const url = langParams ? `${urls.purchaseMulti}?${paramsToQueryString(langParams)}` : urls.purchaseMulti;
        const type = getType('MONETIZERS', url);

        return isError(state, type);
    }
);

export const isFetchingGSTDetailsError = createSelector(
    state => state,
    getLangParams,
    (state, langParams) => {
        const { monetizer: { gstin } = {}} = state;
        const queryParams = { gstin, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.getGSTDetails}?${paramsToQueryString(queryParams)}` : urls.getGSTDetails;
        const type = getType('MONETIZERS', url);

        return isError(state, type);
    }
);

export const isFetchingBillingInfoError = createSelector(
    state => state,
    (state, customParams) => customParams,
    getLangParams,
    (state, customParams, langParams) => {
        const queryParams = { ...customParams, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.billing}?${paramsToQueryString(queryParams)}` : urls.billing;
        const type = getType('MONETIZERS', url);

        return isError(state, type);
    }
);

export const isSavingBillingInfoError = createSelector(
    state => state,
    (state, customParams) => customParams,
    getLangParams,
    (state, customParams, langParams) => {
        const queryParams = { ...customParams, ...langParams };
        const isQueryParams = Object.keys(queryParams).length > 0;
        const url = isQueryParams ? `${urls.saveBilling}?${paramsToQueryString(queryParams)}` : urls.saveBilling;
        const type = getType('MONETIZERS', url);

        return isError(state, type);
    }
);

export const purchaseMultiCartSelector = createSelector(
    state => state.monetizer,
    getLangParams,
    (monetizer, langParams) => {
        const url = langParams ? `${urls.purchaseMulti}?${paramsToQueryString(langParams)}` : urls.purchaseMulti;
        const type = getType('MONETIZERS', url);

        return monetizer.collections[type];
    }
);

export const isFetchingPayment = createSelector(
    state => state,
    getLangParams,
    (state, langParams) => {
        const url = langParams ? `${urls.purchase}?${paramsToQueryString(langParams)}` : urls.purchase;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);

export const purchaseSelector = createSelector(
    state => state.monetizer,
    getLangParams,
    (monetizer, langParams) => {
        const url = langParams ? `${urls.purchase}?${paramsToQueryString(langParams)}` : urls.purchase;
        const type = getType('MONETIZERS', url);

        return monetizer.collections[type];
    }
);

export const getConsumablePackagesSelector = createSelector(
    state => state.monetizer,
    getLangParams,
    (monetizer, langParams) => memoize(
        featureType => {
            const queryParams = {
                featureType,
                ...langParams
            };
            const url = `${urls.consumablePackages}?${paramsToQueryString(queryParams)}`;
            const type = getType('MONETIZERS', url);

            return monetizer.collections[type];
        }
    )
);

export const isConsumablePackagesError = createSelector(
    state => state,
    getLangParams,
    (state, langParams) => memoize(
        featureType => {
            const queryParams = {
                featureType,
                ...langParams
            };
            const url = `${urls.consumablePackages}?${paramsToQueryString(queryParams)}`;
            const type = getType('MONETIZERS', url);

            return isError(state, type);
        }
    )
);

export const packagesSelector = createSelector(
    state => state.monetizer,
    monetizer => memoize(
        queryParams => {
            const url = `${urls.getPackagesList}?${paramsToQueryString(queryParams)}`;
            const type = getType('MONETIZERS', url);

            return monetizer.collections[type];
        }
    )
);

export const isPackagesLoading = createSelector(
    state => state,
    state => memoize(
        queryParams => {
            const url = `${urls.getPackagesList}?${paramsToQueryString(queryParams)}`;
            const type = getType('MONETIZERS', url);

            return isFetching(state, type);
        }
    )
);

export const getLocationByIdSelector = createSelector(
    state => state.locations,
    getLangParams,
    (locations, langParams) => memoize(
        locationId => {
            const url = langParams ? `${locationsUrls.getLocation(locationId)}?${paramsToQueryString(langParams)}` : `${locationsUrls.getLocation(locationId)}`;
            const type = getType('LOCATIONS', url);

            return locations.collections[type];
        }
    )
);

export const getPackagesListById = createSelector(
    state => state.monetizer,
    monetizer => memoize(
        (packageId, queryParams) => {
            const url = `${urls.getPackagesListById}/${packageId}?${paramsToQueryString(queryParams)}`;
            const type = getType('MONETIZERS', url);

            return monetizer.collections[type];
        }
    )
);

export const isRenewPackageLoading = createSelector(
    state => state,
    state => memoize(
        (packageId, queryParams) => {
            const url = `${urls.getPackagesListById}/${packageId}?${paramsToQueryString(queryParams)}`;
            const type = getType('MONETIZERS', url);

            return isFetching(state, type);
        }
    )
);

export const getRenewalPackagesList = state => {
    const url = `${urls.renewPackages}?renewable=true`;
    const type = getType('MONETIZERS', url);

    return state.monetizer.collections[type];
};

export const isFetchingRenewalPackagesList = createSelector(
    state => state,
    state => {
        const url = `${urls.renewPackages}?renewable=true`;
        const type = getType('MONETIZERS', url);

        return isFetching(state, type);
    }
);
