import { get as serverGet, post } from 'Server';
import { urls, customActions } from 'Reducers/users';
import { getUrlNextPage } from 'Reducers/componentHelpers';
import { isEmpty } from 'Helpers/objects';
import {
    isUserIsFollowingFetching,
    isGetUserFetching,
    userSelector,
    userFollowingSelector,
    friendsInCommonSelector,
    isFriendsInCommonFetching,
    followersSelector,
    followingSelector
} from 'Selectors/users';
import {
    userIsLogged,
    getLoggedUser
} from 'Selectors/user';
import { getSelectedLangCode } from 'Selectors/config';

export const getUser = (userId, force = false, params = {}) => (dispatch, getState, { get }) => {
    if (!userId) {
        return Promise.reject();
    }
    const state = getState();
    const user = userSelector(state, userId);

    if (!force && (user || isGetUserFetching(state, userId))) {
        return Promise.resolve();
    }

    return dispatch(get(urls.getExistingUser(userId), 'ELEMENT_USERS', params));
};

export const getUserIsFollowing = userId => (dispatch, getState, { head }) => {
    const state = getState();
    const myUser = getLoggedUser(state);

    if (!userId || !myUser.id) {
        return Promise.reject();
    }
    const following = userFollowingSelector(state, userId);

    if (following || isUserIsFollowingFetching(state, userId)) {
        return Promise.resolve();
    }

    return dispatch(head(urls.userIsFollowing(myUser.id, userId), 'USERS'));
};

export const followUser = userId => (dispatch, getState) => {
    const state = getState();
    const myUser = getLoggedUser(state);

    if (!userId || !myUser.id) {
        return Promise.reject();
    }

    return dispatch(post(urls.followUser(myUser.id), 'USERS', {
        userIds: [userId]
    })).then(
        () => {
            const lang = state.api && state.api.queryParam && state.api.queryParam.enableMultiLang && state.api.queryParam.lang;
            const param = lang ? { lang } : null;

            dispatch(customActions.operateCounter(myUser.id, 'following', 'SUM', param, userId));
        }
    );
};

export const unFollowUser = userId => (dispatch, getState) => {
    const state = getState();
    const myUser = getLoggedUser(state);

    if (!userId || !myUser.id) {
        return Promise.reject();
    }

    return dispatch(post(urls.unFollowUser(myUser.id), 'USERS', {
        userIds: [userId]
    })).then(
        () => {
            const lang = state.api && state.api.queryParam && state.api.queryParam.enableMultiLang && state.api.queryParam.lang;
            const param = lang ? { lang } : null;

            dispatch(customActions.operateCounter(myUser.id, 'following', 'SUBTRACT', param, userId));
        }
    );
};

export const getMeta = userId => (dispatch, getState, { get }) => {
    if (!userId) {
        return Promise.reject();
    }
    return dispatch(get(urls.getMeta(userId), 'USERS'));
};

export const getFriendsInCommon = userId => (dispatch, getState, { get }) => {
    const state = getState();

    if (!userId || !userIsLogged(state)) {
        return Promise.reject();
    }
    const friendsInCommon = friendsInCommonSelector(state, userId);

    if (friendsInCommon.length || isFriendsInCommonFetching(state, userId)) {
        return Promise.resolve();
    }

    return dispatch(get(urls.getFriendsInCommon(userId), 'USERS', {
        query: { social_network: 'platform' }
    }));
};

export const getFollowers = (userId, config = {}) => (dispatch, getState) => {
    const state = getState();

    const followers = followersSelector(state, userId);

    if (followers.length || !userId) {
        return Promise.reject();
    }
    return dispatch(serverGet(urls.getFollowers(userId), 'USERS', {}, {}, { config }));
};

export const loadNextPageFollowers = (userId, params) => (dispatch, getState) => {
    const state = getState();
    const lang = getSelectedLangCode(state);

    if (lang) {
        params.lang = lang;
    }

    dispatch(
        serverGet(getUrlNextPage(state.users, urls.getFollowers(userId), 'USERS', params), 'USERS')
    );
};

export const getFollowing = (userId, config = {}) => (dispatch, getState) => {
    const state = getState();

    const following = followingSelector(state, userId);

    if (following.length || !userId) {
        return Promise.reject();
    }
    return dispatch(serverGet(urls.getFollowing(userId), 'USERS', {}, {}, { config }));
};

export const loadNextPageFollowing = (userId, params) => (dispatch, getState) => {
    const state = getState();
    const lang = getSelectedLangCode(state);

    if (lang) {
        params.lang = lang;
    }

    dispatch(
        serverGet(getUrlNextPage(state.users, urls.getFollowing(userId), 'USERS', params), 'USERS')
    );
};

export const findUser = params => dispatch => {
    if (isEmpty(params)) {
        return Promise.reject();
    }

    return dispatch(post(urls.findUserV2, 'USERS', params));
};
