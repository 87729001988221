import React from 'react';
import { isMobile } from './devices';

// count of cards than can be part of LCP on page laod
export const LCP_CARD_CANDIDATE = {
    desktop: 7,
    mobile: 2
};

export const getLCPCardCandidate = pos => {
    if (isMobile) {
        return pos <= LCP_CARD_CANDIDATE.mobile;
    }

    return pos <= LCP_CARD_CANDIDATE.desktop;
};

export const injectLcpPreloads = (lcpUrls = []) => {
    const QUALITY_IMAGE_60 = 60;

    return lcpUrls.map((url, index) => {
        const sizes
            = '(min-width: 1280px) 15vw, (min-width: 1024px) 20vw, (min-width: 960px) 25vw, (min-width: 540px) 25vw, (min-width: 320px) 30vw, 35vw';
        const src = `${url};s=272x0`;
        const srcSet = `
            ${url};s=100x200;q=${QUALITY_IMAGE_60} 100w,
            ${url};s=200x400;q=${QUALITY_IMAGE_60} 200w,
            ${url};s=300x600;q=${QUALITY_IMAGE_60} 300w,
            ${url};s=400x800;q=${QUALITY_IMAGE_60} 400w,
            ${url};s=600x1200;q=${QUALITY_IMAGE_60} 600w
        `.trim();

        return (
            <link
                key={ index }
                rel="preload"
                href={ src }
                as="image"
                type="image/jpeg"
                imagesrcset={ srcSet }
                imagesizes={ sizes }
                importance="high"
                fetchpriority="high"
            />
        );
    });
};
